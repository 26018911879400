import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem
} from 'reactstrap';

import { images } from '../constants';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import * as navLinkItem from './PageData';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    navigate(`/${lng}`, { replace: true });
  };

  const toggle = () => setIsOpen(!isOpen);

  const toggleMobile = () => window.innerWidth <= 770 ? setIsOpen(!isOpen) : null;

  return (
    <>
      <div className="bg-light">
        <Navbar color="light" light expand="lg" className={'container'}>

          <Link className="navbar-brand w-[195px]" to={'//icarmenia.am/' + i18n.language}>
            <img className='nav-logo' src={images.NavbarLogo} alt={'IC Armenia office logo'}/>
          </Link>

          <NavbarToggler className='bg-light' onClick={toggle}/>

          <Collapse isOpen={isOpen} navbar>
            <Nav navbar>
              <NavItem>
                <Link className="nav-link" to={process.env.REACT_APP_URL}>
                  <div className="dropdown">
                    <button className="dropbtn">
                      IC Armenia
                    </button>
                    <div className="dropdown-content">

                      <Link onClick={toggleMobile}
                        to={`${process.env.REACT_APP_URL}/${i18n.language + t('/SME-Council/Meetings-minutes')}`}>
                        {t('Council Meetings')}
                      </Link>

                      <hr className="navbar_hr"/>

                      <Link onClick={toggleMobile}
                        to={`${process.env.REACT_APP_URL}/${i18n.language + t('/We/Reports')}`}>
                        {t('Annual Reports')}
                      </Link>
                      <hr className="navbar_hr"/>

                      <Link onClick={toggleMobile}
                        to={`${process.env.REACT_APP_URL}/${i18n.language + t('/We/our-team')}`}>
                        {t('Team')}
                      </Link>
                      <hr className="navbar_hr"/>

                      <Link onClick={toggleMobile}
                        to={`${process.env.REACT_APP_URL}/${i18n.language + t('/SME-Council/Mission-Structure')}`}>
                        {t('SME Development Council')}
                      </Link>

                      <hr className="navbar_hr"/>

                      <Link onClick={toggleMobile}
                        to={`${process.env.REACT_APP_URL}/${i18n.language + t('/Gender-Corner/Gender-equality')}`}>
                        {t('Gender Equality')}
                      </Link>

                    </div>
                  </div>
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link"
                  to={`${process.env.REACT_APP_URL}/${i18n.language + t('/Projects-reforms/Delivered-projects-reforms')}`}>
                  <div className="dropdown">
                    <button className="dropbtn">
                      {t('Reforms')}
                    </button>

                  </div>
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link"
                  to={`${process.env.REACT_APP_URL}/${i18n.language + t('/sections/news-date')}`}>
                  <div className="dropdown">
                    <button className="dropbtn">
                      {t('News')}
                    </button>

                  </div>
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link"
                  to={`${process.env.REACT_APP_URL}/${i18n.language + t('/Useful-materials/Business-Guidelines')}`}>
                  <div className="dropdown">
                    <button className="dropbtn">
                      {t('Useful materials')}
                    </button>

                  </div>
                </Link>
              </NavItem>
              <NavItem className={'lang'}>
                <div className="dropdown">
                  <button className="dropbtn w-[140px]">  {navLinkItem?.locales[i18n.language].name}
                    <i className="fas fa-caret-down"/>
                  </button>

                  <div className="dropdown-content lang_dropdown top-[44px] left-0">
                    {Object.keys(navLinkItem?.locales).map((locale) => (
                      <a
                        key={locale}
                        onClick={() => changeLanguage(locale)}
                        className={'cursor-pointer'}
                      >
                        {navLinkItem?.locales[locale].name}
                      </a>
                    ))}
                  </div>
                </div>
              </NavItem>
            </Nav>
          </Collapse>

        </Navbar>
      </div>

    </>
  );
};

export default Header;
