import React from 'react';
import { useTranslation } from 'react-i18next';
import VideoPlayer from '../../../video/VideoPlayer';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const exportVideoLink = (htmlString) => {
  console.log(htmlString);
  if(htmlString) {
    // Create a temporary element (e.g., a div) to parse the HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;

    // Find the iframe element and get its src attribute value
    const iframeElement = tempElement.querySelector('iframe');
    const srcAttributeValue = iframeElement ? iframeElement.getAttribute('src') : null;
    if(srcAttributeValue) {
      // Log the src attribute value
      return srcAttributeValue;
    } else {
      const error = 'embed code is incorrect aa';
      toast.error(error);
      console.log(error);
    }
  } else {
    const error = 'embed code is incorrect';
    toast.error(error);
    console.log(error);
  }
};

const Reform = ({ reforms }) => {
  const { i18n, t } = useTranslation();

  return (
    <div className='reform-container'>
      <div className='container'>
        <div className='card-container'>

          {reforms && reforms.map((reform, i) => (
            <div key={i.toString()} className='card'>
              <div className='w-full flex items-center flex-col'>
                <VideoPlayer videoSource={exportVideoLink(reform.video_source)}/>
                <h2>{reform['text_' + i18n.language] }</h2>
              </div>
              <div className='gap-[1.625rem] flex flex-col items-center'>
                {reform['file_' + i18n.language] && <Link to={process.env.REACT_APP_IMAGE_URL + '/programs_reforms/' + reform['file_' + i18n.language]}
                  target="_blank"
                  className="btn btn-primary download-pdf-btn" rel="noreferrer">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.0538 11.5412L15.9968 9.59821C17.7637 7.83122 17.7637 4.96638 15.9968 3.1994V3.1994C14.2298 1.43242 11.3649 1.43242 9.59796 3.1994L7.65501 5.14236"
                      stroke="white" strokeLinecap="round"/>
                    <path
                      d="M5.15259 7.64453L3.1995 9.59762C1.43252 11.3646 1.43252 14.2294 3.1995 15.9964V15.9964C4.96648 17.7634 7.83132 17.7634 9.5983 15.9964L11.5514 14.0433"
                      stroke="white" strokeLinecap="round"/>
                    <path d="M11.7883 7.24023L6.63584 12.3927" stroke="white" strokeLinecap="round"/>
                  </svg>
                  {reform[`file_name_${i18n.language}`] ? reform[`file_name_${i18n.language}`] : t('Open PDF')}</Link>}
                {reform['file2_' + i18n.language] && <Link to={process.env.REACT_APP_IMAGE_URL + '/programs_reforms/' + reform['file2_' + i18n.language]} target='_blank'
                  className='btn btn-primary download-pdf-btn' rel="noreferrer">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.0538 11.5412L15.9968 9.59821C17.7637 7.83122 17.7637 4.96638 15.9968 3.1994V3.1994C14.2298 1.43242 11.3649 1.43242 9.59796 3.1994L7.65501 5.14236" stroke="white" strokeLinecap="round"/>
                    <path d="M5.15259 7.64453L3.1995 9.59762C1.43252 11.3646 1.43252 14.2294 3.1995 15.9964V15.9964C4.96648 17.7634 7.83132 17.7634 9.5983 15.9964L11.5514 14.0433" stroke="white" strokeLinecap="round"/>
                    <path d="M11.7883 7.24023L6.63584 12.3927" stroke="white" strokeLinecap="round"/>
                  </svg>
                  {reform[`file2_name_${i18n.language}`] ? reform[`file2_name_${i18n.language}`] : t('Open PDF')}</Link>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Reform;
