import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import './App.css';
import HomePage from './pages/home/HomePage';

function App() {
  const location = useLocation();

  console.log('location', location);
  return (
    <div className="App font-montserratArmRegular">
      <Routes>
        <Route index path="/:lang?/" element={<HomePage />} />
      </Routes>
      <Toaster />
    </div>
  );
}

export default App;
