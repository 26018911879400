import React from 'react';
import ResponsiveEmbed from 'react-responsive-embed';

const VideoPlayer = ({ videoSource, title }) => {
  return (
    <div className="max-w-screen-xl mx-auto w-full">
      <ResponsiveEmbed
        src={videoSource}
        title={title}
        allowFullScreen
        ratio="16:9" // Set the aspect ratio as needed (e.g., "16:9" for widescreen)
      />
    </div>
  );
};

export default VideoPlayer;
