import React, { useState } from 'react';
import { sanitize } from '../utils/miscellaneous';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const NewsletterForm = ({ status, message, onValidated }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);
  const { t } = useTranslation();

  switch (message) {
  case '0 - An email address must contain a single @':
    message = t('An email address must contain a single @');
    break;

  case 'Thank you for subscribing!':
    message = t('Thank you for subscribing!');
    break;
  }

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {
    setError(null);

    if(!email) {
      setError(t('Please enter a valid email address'));
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf('@') > -1 && isFormValidated;
  };

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if(event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if(!message) {
      return null;
    }
    const result = message?.split('-') ?? null;
    if(result?.[0]?.trim() !== '0') {
      return sanitize(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;

    return formattedMessage ? sanitize(formattedMessage) : null;
  };

  return (
    <div className={'subscribe'}>
      <Container className="py-[10px]">
        <Row>
          <Col>

            <div className="subscribe_form flex flex-col">
              <div>
                <input
                  className='m-0 max-w-[350px] w-full border-white text-white border-b-[1px] outline-0 bg-transparent'
                  onChange={(event) => setEmail(event?.target?.value ?? '')}
                  type="email"
                  placeholder={t('Email')}
                  onKeyUp={(event) => handleInputKeyEvent(event)}
                />
              </div>

              <div className="subscribeBtn-container py-[20px]">
                <button className="subscribeBtn block bg-[#1579be] pointer text-white border-0 py-[11px] px-[20px] text-[1rem] uppercase" onClick={handleFormSubmit}>
                  {t('Subscribe')}
                </button>
              </div>

              <div>
                {status === 'sending'
                  ? <p className='text-white'>{t('Sending...')}</p>
                  : null}
                {status === 'error' || error
                  ? (
                    <div
                      className="text-red-700 pt-2"
                      dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}
                    />
                  )
                  : null}
                {status === 'success' && status !== 'error' && !error && (
                  <div className="text-green-200 font-bold pt-2" dangerouslySetInnerHTML={{ __html: sanitize(message) }}/>
                )}
              </div>

            </div>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default NewsletterForm;
