export const navItemsInfo = {
  studioWorks: { name: 'STUDIO WORKS', type: 'link', href: 'hero' },
  aboutUs: { name: 'ABOUT US', type: 'link', href: 'aboutUs' },
  clients: { name: 'OUR CLIENTS', type: 'link', href: 'clients' },
  reviews: { name: 'REVIEWS', type: 'link', href: 'reviews' }
  // instagram: { name: 'INSTAGRAM', type: 'link', href: '//instagram.com' }
  // { name: 'FACEBOOK', type: 'link', href: '//facebook.com' },
  // { name: '+374 10 101010', type: 'link', href: 'tel:+374101010' },
  // { name: 'mediahousearmenia@gmail.com', type: 'link', href: 'mailto:mediahousearmenia@gmail.com' }
  // { name: 'CONTACTS', type: 'link', href: '/contact' },
  // {
  //   name: 'Pages',
  //   type: 'dropdown',
  //   items: [
  //     { title: 'About us', href: '/about' },
  //     { title: 'Contact us', href: '/contact' }
  //   ]
  // }
};

export const locales = {
  en: { title: 'Eng', name: 'English' },
  am: { title: 'Հայ', name: 'Հայերեն' }
};
