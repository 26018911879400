import React, { useEffect, useState } from 'react';
import Reform from './container/Reform';
import { useTranslation } from 'react-i18next';
import { getPageData } from '../../services/index/webSettings';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [reforms, setReforms] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getPageData(i18n.language);
        setReforms(data?.reforms);
      } catch (error) {
        console.log('err', error);
      }
    };

    fetchData();
  }, [i18n]);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    isOpen ? document.body.style.overflowY = 'hidden' : document.body.style.overflowY = 'auto';
  }, [isOpen]);

  return (
    reforms && <>
      <Header toggle={toggle}/>
      <Reform reforms={reforms}/>
      <Footer/>
    </>
  );
};

export default HomePage;
