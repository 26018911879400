import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { images } from '../constants';
import NewsletterSubscribe from './NewsletterSubscribe';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className='scrollingFooter bg-[#1579be] text-white'>
        <p className='text-center uppercase text-[0.65rem] md:text-[1rem] py-[6px] px-[5px] text-white tracking-0 md:tracking-[2px]'>{t('Implementing successful reforms since 2007')} </p>
      </div>
      <div className="bg-[#003d60] text-white py-[50px]" style={{ backgroundImage: `url(${images.Footer})` }}>

        <div className="container">
          <div className="row gap-8 md:gap-0">
            <div className="contact-us col-lg-6 col-md-6 col-sm-12 col-12">

              <h3 className='text-[1.5rem] mb-[1px]'>{t('CONTACT US')}</h3>

              <Link className="contact-links"
                to="https://goo.gl/maps/Xj4tEd4EF9CeBsJo7" target="_blank" rel="noreferrer">
                <i className="fas fa-home"/>
                {t('10 V. Sargsyan, 0010 Yerevan')}
              </Link>

              <Link className="contact-links text-inherit" to="mailto: info@icarmenia.am">
                <i className="fa fa-envelope"/>
                info@icarmenia.am
              </Link>
              <Link className="contact-links" to="tel: +37411703370">
                <i className="fa fa-phone-alt"/>
                +37411703370
              </Link>

            </div>
            <div className="subscribe-us col-lg-6 col-md-6 col-sm-12 col-12 text-end">
              <div className={'text-start d-inline-block'}>
                <h3 className='text-[1.5rem] mb-[1px]'>{t('SUBSCRIBE TO IC ARMENIA NEWS')}</h3>

                <NewsletterSubscribe/>

                <div>
                  <Link className="social-links" target="_blank" rel="noreferrer"
                    to="https://www.facebook.com/InvestmentCouncilArmenia">
                    <i className="fab fa-facebook-f"/>
                  </Link>
                  <Link className="social-links" target="_blank" rel="noreferrer"
                    to="https://www.linkedin.com/company/icarmenia/">
                    <i className="fab fa-linkedin-in"/>
                  </Link>
                  <Link className="social-links" target="_blank" rel="noreferrer"
                    to="//www.youtube.com/channel/UCpD0Kap4MBdUY2MW84Uel6A/videos">
                    <i className="fab fa-youtube"/>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

  );
};

export default Footer;
